<template>
  <div class="faq">
    <v-container fluid grid-list-xs>

      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <span class="display-2 font-weight-thin grey--text">FAQs</span>
          <p class="red--text">Currently under construction</p>
        </v-col>
        <v-col cols="12" md="10" offset-md="1" v-for="n in 3" :key="n">
          <!-- FAQs to follow -->
            <v-skeleton-loader
              class="mx-auto my-1"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    
    }
</script>

<style>
.faq {
  min-height: calc(100vh - 165px);
}
</style>